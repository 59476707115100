
import { Options, Vue } from 'vue-class-component'
import store from '../store'
import { useRoute, useRouter } from 'vue-router'
import router from "@/router";
import gdroidService from '@/service/gdroid.service';

@Options({
  components: {
  },
  data() {
    return {
      global: store.state
    }
  },
  mounted: function() {
    console.log('signout view mounted')
    gdroidService.signout().then(() => {
      router.push("/")
    })
  }
})
export default class SignOutView extends Vue {}
