
import { Options, Vue } from 'vue-class-component'
import HelloWorld from '@/components/HelloWorld.vue'
import store from './../store'
import gdroidService from '@/service/gdroid.service'

@Options({
  components: {
    HelloWorld,
  },
  data() {
    return {
      global: store.state,
      service: gdroidService
    }
  }
})
export default class HomeView extends Vue {}
