
import { Options, Vue } from 'vue-class-component'
import store from '../store'
import { useRoute, useRouter } from 'vue-router'
import router from "@/router";

@Options({
  components: {
  },
  data() {
    return {
      global: store.state
    }
  },
  mounted: function() {
    console.log('signin view mounted', useRoute().query)
    router.push("/")
  }
})
export default class SignInView extends Vue {}
