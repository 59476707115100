import { createStore } from 'vuex'

export default createStore({
  state : {
    oAuthServerUrlTemplate: "https://rocket-science.gleeze.com/authenticate?information=",
    serverUrlTemplate: process.env.NODE_ENV === "production" ? "https://gdroid.gleeze.com/api" : "http://localhost:7070/api",
    codeSnippetServiceUrlTemplate: "https://rocket-sciance.github.io/code-snippet",
    oAuthTokenLocalStorageKeyName: "gdeat-gpeat-oa",
    oAuthToken: "",
    serverAuthTokenLocalStorageKeyName: "gdeat-gpeat-sa",
    serverAuthToken: "",
    clientNotificationTokenLocalStorageKeyName: "gdeat-gpeat-nt",
    clientNotificationToken: "",
    user: undefined
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})