
import { Options, Vue } from 'vue-class-component'
import store from '../store'

@Options({
  components: {
  },
  data() {
    return {
      page: {
      }
    }
  }
})
export default class NotFoundView extends Vue {}
