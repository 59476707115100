import { createDeviceDetector } from "next-vue-device-detector";
import axios from 'axios'
import store from './../store'

export default new class {
  async serverCallGetUser() {
    const path = '/ecosystem/user'
    const apiServiceUrl = `${store.state.serverUrlTemplate}${path}`
    
    console.log('gdroid-api ➤➤➤ ', apiServiceUrl)
    
    return axios.get(apiServiceUrl, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${store.state.serverAuthToken}`
        }
      })
    }

  async serverCallPostAuth(authType: string, oAuthToken: string) {
    const path = '/auth'
    const apiServiceUrl = `${store.state.serverUrlTemplate}${path}`
        const request = {
          authType: authType,
          authJwtTokenBase64: oAuthToken,
          serverAuthToken: store.state.serverAuthToken,
          notificationToken: localStorage.getItem(store.state.clientNotificationTokenLocalStorageKeyName),
          extraInformation: {
            browser: window.navigator.userAgent,
            device: createDeviceDetector()
          }
        }

    console.log('gdroid-api ➤➤➤ ', apiServiceUrl, request)
    
    return axios.post(apiServiceUrl, request, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${store.state.serverAuthToken}`
        }
      })
    }
  
  async signin(oAuthToken: string) {
    await this.serverCallPostAuth('signin', oAuthToken)
      .then((response) => {
        const serverAuthToken = response.data
        store.state.oAuthToken = oAuthToken
        store.state.serverAuthToken = serverAuthToken
        store.state.user = JSON.parse(atob(serverAuthToken))
        localStorage.setItem(store.state.oAuthTokenLocalStorageKeyName, oAuthToken)
        localStorage.setItem(store.state.serverAuthTokenLocalStorageKeyName, serverAuthToken)
      })
    }

  async notificationToken(oAuthToken: string) {
    await this.serverCallPostAuth('notificationToken', oAuthToken)
      .then((response) => {
        const serverAuthToken = response.data
        store.state.oAuthToken = oAuthToken
        store.state.serverAuthToken = serverAuthToken
        store.state.user = JSON.parse(atob(serverAuthToken))
        localStorage.setItem(store.state.oAuthTokenLocalStorageKeyName, oAuthToken)
        localStorage.setItem(store.state.serverAuthTokenLocalStorageKeyName, serverAuthToken)
      })
    }  

  async signout() {
    await this.serverCallPostAuth('signout', store.state.oAuthToken)
      .then((response) => {
        store.state.oAuthToken = ""
        store.state.serverAuthToken = ""
        store.state.user = undefined
        localStorage.removeItem(store.state.oAuthTokenLocalStorageKeyName) //, state.oAuthToken)
        localStorage.removeItem(store.state.serverAuthTokenLocalStorageKeyName) //, state.serverAuthToken)
        console.log('store (signout)', store.state.user, "'", store.state.oAuthToken, "', '", store.state.serverAuthToken, "'")
      })
    }

  async getCodeSnippetUrl(sampleName: string) {
    const apiServiceUrl = `/img/data/${sampleName}` //const apiServiceUrl = `${store.state.currentAppUrlTemplate}${path}`
    
    const response = axios.get(apiServiceUrl, {
        headers: {
          'Content-Type': 'application/text',
          'Authorization': `Bearer ${store.state.serverAuthToken}`
        }
      })
    
      const responseContent = await response
      console.log('gdroid-api ➤➤➤ ', apiServiceUrl, 'responseContent', responseContent)

      const responseContentBase64Encoded = btoa(responseContent.data)
      console.log('responseContentBase64Encoded ➤➤➤ ', responseContentBase64Encoded)

      const snippetUrl = `${store.state.codeSnippetServiceUrlTemplate}?content=${responseContentBase64Encoded}`
      console.log('snippetUrl ➤➤➤ ', snippetUrl)

      return snippetUrl;
    }
}