import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-27cc959a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "home"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  class: "sigin"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.global.user)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("img", {
          alt: "User",
          src: _ctx.global.user.pictureLink,
          referrerpolicy: "no-referrer"
        }, null, 8, _hoisted_2),
        _createElementVNode("h2", null, _toDisplayString(_ctx.global.user.email), 1),
        _createElementVNode("div", null, [
          _createVNode(_component_router_link, { to: "/signout" }, {
            default: _withCtx(() => [
              _createTextVNode("Sign-out")
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.service.getUser()))
        }, "User")
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createVNode(_component_router_link, { to: "/signin" }, {
            default: _withCtx(() => [
              _createTextVNode("Sign-in")
            ]),
            _: 1
          })
        ])
      ]))
}