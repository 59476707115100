import { createRouter, createWebHistory, RouteLocationNormalized, RouteRecordNormalized, RouteRecordRaw } from 'vue-router'
import { List } from 'linq.ts';
import store from '@/store'
import gdroidService from '@/service/gdroid.service';

import HomeView from '../views/HomeView.vue'
import SignInView from '../views/SignInView.vue'
import SignOutView from '../views/SignOutView.vue'
import NotFoundView from '../views/NotFoundView.vue'

const freeRoutes = [
  "/",
  "/about"
]

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/signin',
    name: 'signin',
    component: SignInView
  },
  {
    path: '/signout',
    name: 'signout',
    component: SignOutView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFoundView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from) => {
  if (to.query.authentication) {
    const oAuthToken = to.query.authentication as string
    if (null !== oAuthToken) {
      gdroidService.signin(oAuthToken).then(() => {
        if (to.query.information)
        {
          const redirectUrlBase64 = to.query.information as string
          const redirectUrl = new URL(atob(redirectUrlBase64))
          console.log('redirect', redirectUrl)
          router.push(redirectUrl.pathname)
          return false;
        }
      })
    }
  }
  else if (store.state.user === undefined) {
    if (null !== localStorage.getItem(store.state.oAuthTokenLocalStorageKeyName)) {
      const oAuthToken = localStorage.getItem(store.state.oAuthTokenLocalStorageKeyName) as string
      gdroidService.signin(oAuthToken)
      return true;
    }
    else {
      if (freeRoutes.includes(to.path) || new List<RouteRecordNormalized>(router.getRoutes()).FirstOrDefault(x => x?.path === to.path) == null) {
        // Do nothing... Let it load...
        return true;
      }
      else {
        const redirectUrl = location.protocol + "//" + location.host + to.fullPath
        const redirectUrlBase64 = btoa(redirectUrl)
        const authaServerCallUrl = store.state.oAuthServerUrlTemplate + redirectUrlBase64
        console.log("oAuthToken Redirect", authaServerCallUrl)
        window.location.href = authaServerCallUrl
        return false
      }
    }
  }
  return true;
})

export default router
